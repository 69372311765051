<!--
 * @Author: HJ
 * @Date: 2024-08-12 16:56:32
 * @LastEditors: HJ
 * @LastEditTime: 2024-08-17 21:56:37
 * @Description: 
 * @FilePath: \order\src\views\home.vue
-->
<template>
  <div class="app-container">
    <el-row :gutter="10">
      <el-col :span="20" :offset="2">
        <el-form
          style="height: 55px"
          :model="form"
          ref="form"
          label-width="0px"
          inline
        >
          <el-form-item label="" prop="name">
            <el-select
              v-model="form.selectValue"
              size="mini"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label-width="100px"
            label="请求间隔时间"
            prop="name"
          >
            <el-input
              v-model="form.time"
              placeholder="请求间隔时间"
              size="mini"
            ></el-input>
          </el-form-item>
          <el-form-item
            label-width="120px"
            label="登录达到设备数量"
            prop="name"
            v-if="form.selectValue === '1'"
          >
            <el-input
              v-model="form.deviceCount"
              placeholder="请输入登录达到设备数量"
              size="mini"
            ></el-input>
          </el-form-item>

          <el-form-item
            label-width="60px"
            label="邀请码"
            prop="name"
            v-if="form.selectValue === '2'"
          >
            <el-input
              v-model="form.code"
              placeholder="请填写邀请码"
              size="mini"
            ></el-input>
          </el-form-item>

          <el-form-item label="" prop="name">
            <el-button
              style="margin-left: 10px"
              type="primary"
              size="mini"
              :loading="loading"
              @click="handleStart"
              >{{ btnText }}</el-button
            >
          </el-form-item>
          <el-form-item label="" prop="name">
            <el-button type="primary" size="mini" @click="test">test</el-button>
          </el-form-item>
        </el-form>
        <el-table
          ref="tableRef"
          @selection-change="handleSelectionChange"
          max-height="500"
          :data="tableData"
          border
          style="width: 100%"
        >
          <el-table-column type="selection" label="序号"> </el-table-column>
          <el-table-column
            align="center"
            type="index"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column align="center" prop="username" label="账号">
          </el-table-column>
          <el-table-column align="center" prop="password" label="密码">
          </el-table-column>
          <el-table-column
            align="center"
            prop="emaiUsername"
            :show-overflow-tooltip="true"
            label="邮箱"
          >
          </el-table-column>
          <el-table-column align="center" prop="emaiPassword" label="邮密">
          </el-table-column>
          <el-table-column
            align="center"
            prop="cookies"
            :show-overflow-tooltip="true"
            label="Cookies"
          >
          </el-table-column>
          <el-table-column align="center" prop="closeStatus" label="掉线状态">
            <template slot-scope="scope">
              <el-tag type="danger" v-if="scope.row.closeStatus === '掉线'">{{
                scope.row.closeStatus
              }}</el-tag>
              <div v-else-if="scope.row.closeStatus === '在线'">
                {{ scope.row.closeStatus }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="status" label="状态">
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { sendCookie, zjTest } from "@/api/Login";
import Cookies from "js-cookie";
export default {
  data() {
    return {
      tableData: [],
      form: {
        selectValue: "0",
        deviceCount: 2,
        code:"",
        time:1000
      },
      currentIndex: 0, // 当前要打印的index
      selection: [],
      isRunning: false, // 控制循环的标志位
      loading: false,
      btnText: "开始执行",
      options: [
        {
          value: "0",
          label: "监控设备",
        },
        {
          value: "1",
          label: "踢出设备",
        },
         {
          value: "2",
          label: "加入企业",
        },
      ],
    };
  },
  mounted() {
    // 绑定剪切板事件监听器
    document.addEventListener("paste", this.handlePaste);
  },
  beforeDestroy() {
    // 组件销毁时解绑剪切板事件监听器，避免内存泄漏
    document.removeEventListener("paste", this.handlePaste);
  },
  methods: {
    //粘贴
    handlePaste(event) {
      let list = event.clipboardData.getData("text")?.split("\n") || [];
      // console.log('list', list)
      list.map((item) => {
        console.log("item", item?.split("----"));
        let temp = item?.split("----");
        console.log("temp", temp);
        //最后一个防止换行
        if (temp[0] == "" || temp[0] == " ") return;
        this.tableData.push({
          id: this.tableData.length,
          username: temp[0],
          password: temp[1],
          emaiUsername: temp[2],
          emaiPassword: temp[3],
          cookies: temp[4],
        });
      });
      this.$refs.tableRef.toggleAllSelection();
    },

    async processNextItem() {
      if (this.currentIndex < this.selection.length) {
        console.log(this.currentIndex); // 打印当前index
        const { data: res } = await sendCookie({
          cookie: this.selection[this.currentIndex].cookies,
          type: this.form.selectValue,
          removeCount:
            this.form.selectValue == "1" ? this.form.deviceCount : null,
            code: this.form.selectValue == "2" ? this.form.code : "",
        }).catch((res=>{
          this.loading = false;
        this.btnText = "开始执行";
        this.isRunning = false;
        }));
        console.log("res", res);
        if ((res.code == 1 && res.data) || res.data == 0) {
          this.$set(
            this.tableData[this.selection[this.currentIndex].id],
            "closeStatus",
            "在线"
          );
          // this.$set(
          //   this.tableData[this.selection[this.currentIndex].id],
          //   "cookies",
          //   res.data
          // );
          let text;
          if (typeof res.data === "number") {
            text = "踢出" + res.data + "台";
          } else {
            text = (res.data.count || 0) + "台";
          }
          this.$set(
            this.tableData[this.selection[this.currentIndex].id],
            "status",
            text
          );
        } else {
          this.$set(
            this.tableData[this.selection[this.currentIndex].id],
            "closeStatus",
            "掉线"
          );
        }

        this.currentIndex++; // 递增index
        setTimeout(() => {
          this.processNextItem(); // 递归调用，直到所有index都打印完
        }, this.form.time); // 设置延时为2秒
      } else {
        //全部执行完成

        console.log("全部执行完成...");
        this.selection.forEach((item) => {
          if (item.closeStatus === "掉线")
            return this.$refs.tableRef.toggleRowSelection(item, false);
        });
        this.$alert("全部执行完成");
        this.loading = false;
        this.btnText = "开始执行";
        this.isRunning = false;
      }
    },
    //开始执行
    handleStart() {
      if (!this.isRunning) {
        this.isRunning = true;
        this.loading = true;
        this.btnText = "执行中...";
        this.currentIndex = 0; // 在开始处理前重置currentIndex
        this.processNextItem();
      }
    },
    handleSelectionChange(val, index) {
      console.log("val", val, index);
      this.selection = val;
    },
    test1() {
      this.$refs.tableRef.toggleRowSelection(this.tableData[0], false);
    },
    test() {
      // 将数据转换为字符串
      let text = "";
      this.selection.forEach((item, index) => {
        // 检查是否是最后一个元素
        const isLastItem = index === this.selection.length - 1;
        text += `${item.username}----${item.password}----${
          item.emaiUsername
        }----${item.emaiPassword}----${item.cookies}${isLastItem ? "" : "\r\n"}`;
      });

      // 创建一个Blob对象
      const blob = new Blob([text], { type: "text/plain" });

      // 创建一个指向Blob的URL
      const url = URL.createObjectURL(blob);

      // 创建一个临时的a标签
      const a = document.createElement("a");
      a.href = url;
      a.download = "exported_data.txt"; // 设置下载文件名

      // 触发下载
      document.body.appendChild(a);
      a.click();

      // 清理
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      //       let cookie = "STOKEN=63ae2a18ebe4421bf39451204467dd6b7f738100a6df3992ca93f45282f2add9; BDUSS=tNMXJ2RG8teHhKcmZ3M256eFdHS0pyOC1-c05LUEdjWlpHSGJBcnI3cDVkbFZtSVFBQUFBJCQAAAAAAAAAAAEAAABADLN60~fA1tDEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHnpLWZ56S1mQ2; PTOKEN=b69a4c8ce4ec1b412927749771f122d4; PASSID=D4wTZq; UBI=fi_PncwhpxZ~TaNj16pDLdLhpsZthNZAxXLsUeaXxQ1rfV8dqv6r0z~lMc1oIVOux4BP1tftGA4Il-fs6FZe1MvQyWJgVVSZLXPeaAauZ1GpMWPvY8QYt9khC4UrZg~S~stPwp1LkDlkVki3mYTMMS4r9FAww__; BAIDUID=B578665F531B124E062054D9DDCAE8B7:FG=1; BAIDUID_BFESS=B578665F531B124E062054D9DDCAE8B7:FG=1; UBI_BFESS=fi_PncwhpxZ~TaNj16pDLdLhpsZthNZAxXLsUeaXxQ1rfV8dqv6r0z~lMc1oIVOux4BP1tftGA4Il-fs6FZe1MvQyWJgVVSZLXPeaAauZ1GpMWPvY8QYt9khC4UrZg~S~stPwp1LkDlkVki3mYTMMS4r9FAww__; BDUSS_BFESS=3Q2OW4tMnNYZ0hhQnE5cVVGdHZLdUozU0h-SnMwc2VTU0djQzBBOXYtT3hFd2xsSVFBQUFBJCQAAAAAAAAAAAEAAABADLN60~fA1tDEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAALGG4WSxhuFkb; PTOKEN_BFESS=7b50d2e19770573f5991327ab005ed7f; TIEBAUID=4f2f814869cd1ffa1fa64a78; TIEBA_USERTYPE=d5f69cb6857ef10c4cc7251d"
      //      // 分割cookie字符串为数组
      // let cookies = cookie.split('; ');

      // // 遍历数组并设置每个cookie
      // cookies.forEach(cookie => {
      //     let [name, value] = cookie.split('=');
      //     Cookies.set(name, value);
      // });
      //      zjTest().then((res=>{
      //         console.log('res', res)
      //       }))
    },
  },
};
</script>

<style lang="less">
.app-container {
  padding: auto;
}
</style>
