/*
 * @Author: HJ
 * @Date: 2024-07-13 11:24:55
 * @LastEditors: HJ
 * @LastEditTime: 2024-08-19 10:54:59
 * @Description: 
 * @FilePath: \order\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/views/home"
Vue.use(VueRouter)

const routes = [
 { path: '/', redirect: '/home' },
  {
        path: '/home',
        component: Home
      },

  // // { path: '/', redirect: '/layout/essay' },
  // {
  //   path: '/layout',
  //   component: layout,
  //   children: [
  //     // {
  //     //   path: 'home',
  //     //   component: home
  //     // },
  //     {
  //       path: 'essay',
  //       component: essay
  //     },
  //     // {
  //     //   path: 'project',
  //     //   component: project
  //     // },
  //     // {
  //     //   path: 'message',
  //     //   component: message
  //     // },
  //     // {
  //     //   path: 'about',
  //     //   component: about
  //     // },
      

  //   ]

  // }
]

// --------以下为了解决重复点击路由导航报错的问题---------
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
// --------以上为了解决重复点击路由导航报错的问题---------


const router = new VueRouter({
  routes,
  // mode: 'history', // 去掉url中的#
  mode: 'hash',

})

export default router
