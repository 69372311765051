/*
 * @Author: HJ
 * @Date: 2024-07-13 11:24:55
 * @LastEditors: HJ
 * @LastEditTime: 2024-07-13 13:36:26
 * @Description: 
 * @FilePath: \blog\src\utils\request.js
 */
// --------封装axios函数-------
import ajax from 'axios'
// 新建一个新的axios实例
const axios = ajax.create({
    baseURL:process.env.VUE_APP_BASE_API//基地址
})

// axios.interceptors.request.use(config=>{
//     const cookies = "STOKEN=91ada3eb094956050c71eaf758e59e253be7c5ab2f13c37df47745104c201889; BDUSS=UF3LTlhWEJ1VlZyZnd3ak51Yk1SYjBYd1l6eXlyY1o3TmxhMXBSdzBmUWl1MVZtSVFBQUFBJCQAAAAAAAAAAAEAAACA5bB5yszGvcK2AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACIuLmYiLi5mW; PTOKEN=304fa25ff278e654b11487c62b631e9a; PASSID=czNKOt; UBI=fi_PncwhpxZ~TaNj1WgvYYOQzNKP6G7Zt4Rg65mL9lkFSnYfAFKWksjAxcl83TxlLp9Wf-Dud2YWfhO-aDZHx-8i0OLE1Pzdo1UrXRGUXp3YmIAQH52PDbH8BoDw8Ha-pmdMxpyUt2NsNltzt~HtWHx1ejxVQ__; BAIDUID=96126ECDCE21264BD479CCFF1763917D:FG=1; BAIDUID_BFESS=96126ECDCE21264BD479CCFF1763917D:FG=1; UBI_BFESS=fi_PncwhpxZ~TaNj1WgvYYOQzNKP6G7Zt4Rg65mL9lkFSnYfAFKWksjAxcl83TxlLp9Wf-Dud2YWfhO-aDZHx-8i0OLE1Pzdo1UrXRGUXp3YmIAQH52PDbH8BoDw8Ha-pmdMxpyUt2NsNltzt~HtWHx1ejxVQ__; BDUSS_BFESS=W5PNDZWRHVPMH5DRk1ScHBGZFZsb0M5UkMxaGFCVDJCWmxEYkxxRHk5MlRFd2xsSVFBQUFBJCQAAAAAAAAAAAEAAACA5bB5yszGvcK2AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAJOG4WSThuFkS; PTOKEN_BFESS=7f92249e7318327de0b0737f00cd1d7d; TIEBAUID=4a3c80e76611ca81fe358791; TIEBA_USERTYPE=5e2f280b13b8e296ec33c81d"
//     config.headers.Cookie = cookies
//     return config
// })

export default ({url,method='GET',params,data,headers})=>
     axios({
        url,
        method,
        params,
        data,
        headers
    })
